<template>
  <div class="organizations pa-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <div>
          <h1>Administration des organisations</h1>
        </div>
      </div>
      <high-button-slot
        v-if="$hasRight('organizations.create')"
        @click="isCreateOrganizationModaleOpened = true"
        >Nouvelle organisation</high-button-slot
      >
    </div>
    <v-card
      class="organizationCard"
      v-for="organization in organizations"
      :key="organization.id"
      @click="redirectToOrganizationView(organization.id)"
    >
      <div class="nameDiv">
        <p>{{ organization.name }}</p>
      </div>
      <v-chip v-if="userOrganization.id === organization.id"
        >Vous faites partie de cette organisation</v-chip
      ></v-card
    >
    <CreateOrganizationModale
      v-if="isCreateOrganizationModaleOpened"
      @closePopin="isCreateOrganizationModaleOpened = false"
      @organizationCreated="handleNewOrganization"
    />
  </div>
</template>

<script>
import CreateOrganizationModale from "@/components/entities/organization/CreateOrganization.modal";
export default {
  components: {
    CreateOrganizationModale
  },
  data() {
    return {
      isCreateOrganizationModaleOpened: false,
      organizations: [],
      userOrganization: {
        id: ""
      }
    };
  },
  methods: {
    async getOrganizations() {
      this.organizations = await this.$api.organizations.findAll();
    },
    async getUserOrganization() {
      const userId = this.$store.state.user.current.userId;
      if (undefined === userId)
        return setTimeout(this.getUserOrganization, 500);
      this.userOrganization =
        await this.$api.organizations.findUserOrganizations(userId);
    },
    redirectToOrganizationView(id) {
      this.$router.push({
        name: "organization-administration",
        params: { id }
      });
    },
    refreshViewData() {
      this.getUserOrganization();
      this.getOrganizations();
    },
    handleNewOrganization(organization) {
      this.redirectToOrganizationView(organization.id);
    }
  },
  mounted() {
    if (!this.$hasRight("organizations.manageAll"))
      this.$router.push({ path: "/workspaces" });
    this.refreshViewData();
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.organizations {
  height: 100vh;
  overflow-y: scroll;
}

.organizationCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin: 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in;

  .nameDiv {
    display: flex;
    align-items: center;
  }
  p {
    margin-bottom: 0;
    margin-right: 10px;
  }
  &:hover {
    transform: scale(1.01);
  }
}
</style>

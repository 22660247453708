<template>
  <div class="EditPopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Créer une organisation</h3>
      </v-card-title>
      <v-form
        class="form"
        v-model="isFormValid"
        @submit.prevent="confirmModifications()"
      >
        <v-row>
          <v-col cols="12" md="9">
            <v-text-field
              outlined
              autofocus
              tabindex="1"
              clearable
              label="Nom*"
              v-model="newOrganization.name"
              @input="error.display = false"
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              tabindex="2"
              outlined
              label="Interne"
              v-model="newOrganization.isInternal"
            />
          </v-col>
          <v-col>
            <p class="errorText" v-show="error.display">{{ error.text }}</p>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <LowButtonSlot @click="closePopin"> Annuler </LowButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot
          type="submit"
          :_disabled="!isFormValid"
          @click="confirmModifications"
        >
          Valider
        </HighButtonSlot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFormValid: false,
      error: {
        text: "",
        display: false,
      },
      newOrganization: {
        name: "",
        isInternal: false,
      },
    };
  },
  methods: {
    isValidForm() {
      return this.newOrganization.name.length > 3;
    },
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmModifications() {
      if (!this.isValidForm()) {
        this.error.text = "Veuillez remplir le nom de l'organisation";
        this.error.display = true;
        return;
      }

      const response = await this.$api.organizations.create(
        this.newOrganization
      );

      this.$emit("organizationCreated", response);
      this.closePopin();
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }

  .errorText {
    color: red !important;
  }
}
</style>
